<template>
  <div class="aggregate-table-container">
    <div class="aggregate_table_middle" style="margin: 0">
      <a-button
        type="link"
        style="margin-right: 10px"
        @click="handleClickCustTagsBtn"
      ><a-icon type="edit" />自定义指标</a-button
      >
      <a-button
        type="link"
        style="margin-right: 15px"
        @click="downloadReport"
        :loading="downloading"
      ><a-icon type="download" />导出</a-button
      >
    </div>
    <div class="table_wrapper">
      <a-table
        style="padding: 0 0 10px 0"
        :loading="isLoading"
        class="aggregate_table"
        size="middle"
        :pagination="false"
        rowKey="id"
        :columns="columns"
        :data-source="isExpend ? filterData : dataList"
        :scroll="{ x: true }"
        :rowClassName="rowClassName"
        :locale="tablenodata"
      >
        <template :slot="item.prop" v-for="item in sureArr" slot-scope="text, record">
          <div :key="item.prop">
            <!-- 百分比数值 -->
            <span v-if="record.type !== 'expend' && isRota(item.prop)" :title="text">{{ text }}%</span>
            <!-- 填充耗时 -->
            <div
              v-else-if="record.type !== 'expend' && item.prop === 'advResponseTime'"
              style="width: 100%; display: flex; justify-content: center"
              :title="text"
            >
              <RowAdvResponseTime :maxAdvResponseTime="+maxAdvResponseTime" :advResponseTime="+text">
              </RowAdvResponseTime>
            </div>
            <span v-else :title="numFormat(text, 3, '')">{{ numFormat(text, 3, '') }}</span>
          </div>
        </template>
      </a-table>
    </div>
    <SourceModal
      v-bind="$attrs"
      :positionId="positionId"
      v-if="addSourceVisible"
      :visible="addSourceVisible"
      :sourceInfo="isAdd ? undefined : sourceInfo"
      @modalCancel="addSourceVisible = false"
      :isAdd="isAdd"
      :query="{
        ...query,
        ...defaultQuery
      }"
      @changeSource="getTableData"
      groupName="自动运营分组"
    />
    <FrequencyModal
      v-if="frequencyVisible"
      :visible="frequencyVisible"
      :sourceInfo="sourceInfo"
      @modalCancel="frequencyVisible = false"
      @changeSource="getTableData"
      :query="query"
    />
    <CustTagsModal
      :targetArr="targetArr"
      :sureArr="sureArr"
      :storageName="storageName"
      :visible="custTagsVisible"
      @modalCancel="custTagsVisible = false"
      @changeCustTags="changeCustTags"
      :defaultCheckedList="defaultCheckedList"
    />
    <BatchCopyModal
      v-if="batchCopyVisible"
      :visible="batchCopyVisible"
      @modalCancel="batchCopyVisible = false"
      :query="query"
      @batchCopySuccess="getTableData"
    />
    <BatchExportModal
      v-bind="$attrs"
      v-if="batchExportVisible"
      :visible="batchExportVisible"
      @modalCancel="batchExportVisible = false"
      :query="query"
      @batchExportSuccess="getTableData"
    />
    <BatchCopySource
      v-if="batchCopySourceVisible"
      :visible="batchCopySourceVisible"
      @modalCancel="batchCopySourceVisible = false"
      :query="query"
      :sourceList="selectTableItems"
    />
    <WarningModal v-if="warning" :visible="warning" @modalCancel="warning = false" :parameters="query" />
    <a-modal
      :title="`${title}推荐配置`"
      :visible="showSelectRecommendType"
      @ok="handleSelectRecommendType"
      @cancel="showSelectRecommendType = false"
      width="800px"
    >
      <div class="recommend-type-box">
        <div class="recommend-type-header">
          <p>{{ title }}基于平台自有优化经验，动态为你生成推荐瀑布流，进一步提升变现效率。</p>
        </div>
        <div class="recommend-type-content">
          <div class="recommend-type-item" @click="recommendType = 1" :class="{ active: recommendType === 1 }">
            <div class="recommend-type-item-header">
              <div class="title">精简版</div>
              <span class="desc">广告位数量更少、操作成本更低，适合新手使</span>
            </div>
            <div class="recommend-type-item-img">
              <img :src="recommendImg1" alt="" />
            </div>
          </div>
          <div class="recommend-type-item" @click="recommendType = 0" :class="{ active: recommendType === 0 }">
            <div class="recommend-type-item-header">
              <div class="title">标准版</div>
              <span class="desc">瀑布流配置更复杂，适合有一定经验的开发者</span>
            </div>
            <div class="recommend-type-item-img">
              <img :src="recommendImg2" alt="" />
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <adxSourceModal
      v-bind="$attrs"
      :positionId="positionId"
      v-if="adxSourceVisible"
      :visible="adxSourceVisible"
      :sourceInfo="isAdd ? undefined : sourceInfo"
      @modalCancel="adxSourceVisible = false"
      :isAdd="isAdd"
      :query="query"
      @changeSource="getTableData"
    />
  </div>
</template>

<script>
import {
  getAggregateTableData,
  updateSource,
  deleteSource,
  batchUpdateSourceSts,
  platformLinkList,
  copyPublicAdPool
} from '@/api/aggregate'
import { getPlatList } from '@/api/common'
import SwithSvg from '@/assets/icons/swith.svg?inline'
import RowPriorityItem from '@/views/aggregate/Config/aggregateDetail/rowPriorityItem.vue'
import RowPlatItem from '@/views/aggregate/Config/aggregateDetail/rowPlatItem.vue'
import CustTagsModal from '@/components/common/CustTagsModal'
import adxSourceModal from '../../components/adxSourceModal'
import custTagObj from './one'
import localDataName from '@/utils/localDataName'
import RowAdvResponseTime from '@/views/aggregate/Config/aggregateDetail/RowAdvResponseTime.vue'
import { numFormat } from '@/utils/dealNumber'
import { mapState } from 'vuex'
// import { aggregateDataArr } from '@/utils/targetArr'
import excel from '@/utils/excel'
import request from '@/utils/request'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
import EditSvg from '@/assets/icons/more/edit.svg?inline'
import DeleteSvg from '@/assets/icons/more/delete.svg?inline'
import ControlSvg from '@/assets/icons/more/control.svg?inline'
import DetailSvg from '@/assets/icons/more/detail.svg?inline'
import FlowSettingSvg from '@/assets/icons/more/flowSetting.svg?inline'
import StrategySvg from '@/assets/icons/more/share.svg?inline'
import GroupSvg from '@/assets/icons/more/Group.svg?inline'
import ActionIconMixin from '@/mixins/actionIcon'

// 清除过期的自定义指标缓存
const SourceModal = () => import(/* webpackChunkName: "dialog" */ '../../components/sourceModal')
const FrequencyModal = () => import(/* webpackChunkName: "dialog" */ '../../components/frequencyModal')
// const BatchCopySource = () => import(/* webpackChunkName: "dialog" */ '../components/batchCopySourceMpdal')
// const BatchCopyModal = () => import(/* webpackChunkName: "dialog" */ '../components/batchCopyModal')
const WarningModal = () => import(/* webpackChunkName: "dialog" */ '../../components/warningModal')
// const BatchExportModal = () => import(/* webpackChunkName: "dialog" */ '../components/batchExportModal')
export default {
  name: 'AutoTable',
  mixins: [rateAndMoneyMethod, ActionIconMixin],
  components: {
    SwithSvg,
    SourceModal,
    RowPriorityItem,
    FrequencyModal,
    // CustTagsModal,
    CustTagsModal,
    // BatchCopyModal,
    // BatchExportModal,
    RowAdvResponseTime,
    WarningModal,
    adxSourceModal,
    EditSvg,
    DeleteSvg,
    DetailSvg,
    ControlSvg,
    FlowSettingSvg,
    StrategySvg,
    GroupSvg
    // BatchCopySource
  },
  data () {
    return {
      adxSourceVisible: false,
      defaultCheckedList: [],
      // 自定义指标
      targetArr: [], // 指标数组
      storageName: localDataName.AutoReport, // 本地缓存key
      sureArr: [], // 最终展示数组
      sureColumns: [],
      columns: [],
      // sureLength: 0,
      custTagsVisible: false,
      scrollLeft: 0, // 可拖拽table的偏移量
      selectedRowKeys: [],
      // 添加广告源
      addSourceVisible: false,
      // 频次控制
      frequencyVisible: false,
      // 批量复制广告源
      batchCopyVisible: false,
      // 批量导入
      batchExportVisible: false,
      batchCopySourceVisible: false,
      showSelectRecommendType: false,
      recommendImg1: require('@/assets/images/recommend1.png'),
      recommendImg2: require('@/assets/images/recommend2.png'),
      recommendType: 1,
      isAdd: true,
      sourceInfo: {},
      dataList: [],
      hasSdk: -1,
      sdkArr: [],
      sdkNum: 0,
      hasPublic: -1,
      publicArr: [],
      publicNum: 0,
      openNum: 0,
      closeNum: 0,
      biddingOpenNum: 0,
      isLoading: false,
      platArr: [], // 广告平台下拉
      baseColumns: [
        {
          dataIndex: 'sts',
          title: '状态',
          align: 'left',
          width: 70,
          fixed: true,
          // 不支持导入
          customRender: (text, record, index) => {
            const children = (
              <div>
              {
                record.type === 'sdk' && (
                  <a-switch
                    onChange={async (e) => {
                      record.sts = e ? 'A' : 'S'
                      await this.changeSdkSts(e, record)
                    }}
                    size={'small'}
                    checked={record.sts === 'A'}></a-switch>
                )
              }
              {
                record.type === 'sources' && (
                  <a-switch
                    onChange={async (e) => {
                      const row = JSON.parse(JSON.stringify(record))
                      row.sts = e ? 'A' : 'S'
                      row.groupId = this.query.groupId
                      row.testId = this.query.testId
                      row.appId = this.query.appId
                      const resp = await updateSource(row)
                      if (resp.code === 200) {
                        this.$message.success('修改成功！')
                        this.getTableData()
                      }
                    }}
                    size={'small'}
                    checked={record.sts === 'A'}></a-switch>
                )
              }
              </div>
            )
            return {
              children,
              attrs: {
                colSpan: record.type === 'expend' ? 0 : 1
              }
            }
          }
        },
        {
          dataIndex: 'priority',
          title: <div style={{ paddingLeft: '20px' }}>优先级</div>,
          align: 'left',
          width: 100,
          fixed: true,
          // 不支持导入
          customRender: (text, record, index) => {
            return {
              children: (
                <RowPriorityItem
                  style={{ paddingLeft: '20px' }}
                  record={record}
                  index={index}
                  isExpend={this.isExpend}
                  onhandleExpend={this.handleExpend}
                />
              ),
              attrs: {
                colSpan: record.type === 'expend' ? 4 : 1
              }
            }
          }
        },
        {
          title: '广告源',
          dataIndex: 'name',
          align: 'left',
          fixed: true,
          width: '200px',
          customRender: (text, record, index) => {
            return {
              children: <RowPlatItem query={this.query} record={record} index={index} platList={this.platList} />,
              attrs: {
                colSpan: record.type === 'expend' ? 0 : '1'
              }
            }
          }
        },
        {
          title: '价格',
          dataIndex: 'bidfloor',
          align: 'center',
          width: 100,
          fixed: true,
          customRender: (text, record, index) => {
            const obj = {
              children: text,
              attrs: {}
            }
            // 折叠
            if (record.type === 'expend') {
              obj.attrs.colSpan = 0
            }
            // 汇总
            if (record.type === 'all') {
              obj.children = <span>￥{record.placePrice}</span>
            }
            // 竞价（开启）
            if ((record.sts === 'A' && record.settleType) || record.type === 'sdk') {
              obj.children = <span class="primary-text">竞价</span>
            }
            // 固价（开启）
            if (record.sts === 'A' && (record.settleType === 0 || record.type === 'bottoms')) {
              obj.children = (
                <div style="cursor:pointer">
                  <div class="bidfloor">
                    <span
                      title="开启自动价格"
                      style={{ display: record.isAutoSorting === '1' ? 'inline-block' : 'none' }}
                      class={[record.isAutoSorting === '1' ? 'isopen' : 'isclose', 'auto_price']}></span>
                    ￥{record.bidfloor}
                    <a-icon
                      title="修改价格"
                      class="edit_bidfloor_icon"
                      type="edit"
                      onclick={() => {
                        this.$message.warning('自动运营状态不允许修改')
                      }}
                    />
                  </div>
                </div>
              )
            }
            // 竞价（关闭）
            if (record.sts === 'S' && record.settleType) {
              obj.children = <span class="primary-text">竞价</span>
            }
            // 固价（关闭）
            if (record.sts === 'S' && record.settleType === 0) {
              obj.children = <span>￥{record.bidfloor}</span>
            }
            // 兜底（关闭）
            if (record.sts === 'S' && record.type === 'bottoms') {
              obj.children = <span>￥{record.bidfloor}</span>
            }
            obj.attrs = {
              colSpan: record.type === 'expend' ? 0 : '1'
            }
            return obj
          }
        }
      ],
      actionColumn: [
        {
          dataIndex: '',
          title: '操作',
          align: 'center',
          width: 65,
          fixed: 'right',
          customRender: (text, record, index) => {
            const obj = {
              children: text,
              attrs: {}
            }
            // 折叠
            if (record.type === 'expend') {
              obj.attrs.colSpan = 0
            }
            if (record.type !== 'expend' && record.type !== 'all' && record.type !== 'sdk' && record.type !== 'public') {
              obj.children = (
                <a-popover
                  ref={'settingPopover' + index}
                  overlayClassName="more_setting_popover_auto"
                  trigger="hover"
                  placement="bottom">
                  <GroupSvg class="icon more_icon" style="font-size:18px;cursor: pointer;" />
                  <div slot="content">
                    <div class="more_setting" onclick={() => {
                      this.isAdd = false
                      this.sourceInfo = record
                      this.addSourceVisible = true
                      this.$refs['settingPopover' + index].$children[0].sVisible = false
                    }}>
                      <EditSvg class="edit_icon icon primary-text"/>
                      编辑
                    </div>
                    {record.platId < 900 && record.platId !== 5 ? (
                      <div
                        class="more_setting"
                        onclick={() => {
                          this.query.id = record.id
                          this.warning = true
                          this.$refs['settingPopover' + index].$children[0].sVisible = false
                        }}>
                        <a-icon class="waring_icon icon" type="alert" />
                        异常诊断
                      </div>
                    ) : (
                      ''
                    )}
                    <div
                      class="more_setting"
                      onclick={() => {
                        this.sourceInfo = record
                        this.frequencyVisible = true
                        this.$refs['settingPopover' + index].$children[0].sVisible = false
                      }}>
                      <a-icon type="setting" class="setting_icon icon" />
                      频次控制
                    </div>
                    {!!this.publicAdPool && !record.reuse && (this.currentLoginId === 25 || this.role === 'SUPER_ADMIN') && (<a-popconfirm
                      icon={false}
                      title="确认复制该广告源到公共广告位?"
                      ok-text="是"
                      cancel-text="否"
                      onconfirm={async () => {
                        const resp = await copyPublicAdPool({
                          placeId: this.query.placeId,
                          adsourceIdList: [record.id],
                          ...this.defaultQuery
                        })
                        if (resp.code === 200) {
                          this.$message.success('操作成功！')
                          this.getTableData()
                        }
                      }}>
                      <div class="more_setting">
                        <CopyPublicPlaceSvg class="delete_icon icon"/>
                        公共广告位
                      </div>
                    </a-popconfirm>)}
                    <a-popconfirm
                      icon={false}
                      title="确认删除该广告源?"
                      ok-text="是"
                      cancel-text="否"
                      onconfirm={async () => {
                        const resp = await deleteSource({
                          placeId: this.query.placeId,
                          groupId: this.query.groupId,
                          testId: this.query.testId,
                          id: record.id
                        })
                        if (resp.code === 200) {
                          this.$message.success('删除成功！')
                          this.getTableData()
                        }
                      }}>
                      <div class="more_setting">
                        <DeleteSvg class="delete_icon icon"/>
                        删除
                      </div>
                    </a-popconfirm>
                  </div>
                </a-popover>
              )
            }
            if (record.type === 'sdk') {
              if (this.isAdmin) {
                obj.children = (
                <a-popover
                  ref={'settingPopover' + index}
                  overlayClassName="more_setting_popover_auto"
                  trigger="hover"
                  placement="bottom">
                  <GroupSvg class="icon more_icon" style="font-size:18px;cursor: pointer;" />
                  <div slot="content">
                    <div class="more_setting" onclick={() => {
                      this.isAdd = false
                      this.sourceInfo = record
                      this.adxSourceVisible = true
                      this.$refs['settingPopover' + index].$children[0].sVisible = false
                    }}>
                      <EditSvg class="edit_icon icon primary-text"/>
                      编辑
                    </div>
                  </div>
                </a-popover>
              )
              } else {
                obj.children = null
              }
            }
            if (record.type === 'public') {
              obj.children = (
                <GroupSvg class="icon more_icon disabled"/>
              )
            }
            return obj
          }
        }
      ],
      platList: [],
      // 默认传参
      defaultQuery: {
        groupId: 'autoOperator',
        testId: 'default'
      },
      isExpend: true,
      filterData: [],
      timer: null,
      selectTableItems: [],
      downloading: false,
      maxAdvResponseTime: undefined,
      groupList: undefined,
      abTests: undefined,
      warning: false,
      concurrentNum: 0,
      tablenodata: {
        emptyText: () => (
          <m-empty>
            <div slot="description">暂无广告源！</div>
          </m-empty>
        )
      },
      platIds: [],
      publicAdPool: undefined
    }
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    },
    // concurrentNum: {
    //   default: 0,
    //   type: [Number, String]
    // },
    abtestNum: {
      default: 1,
      type: Number
    },
    positionId: {
      default: 0,
      type: [Number, String]
    }
  },
  watch: {
    query: {
      handler (val, oldval) {
        if (!val.placeId || !val.appId) {
          return
        }
        this.getTableData()
        this.getColumns()
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    this.getPlatList()
    // 广告平台下拉
    this.getplatArr()
    // console.log(this.concurrentNum)
  },
  mounted () {
    this.initTargetArr()
  },
  updated () {
    this.scrollInit()
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      allRoutes: (state) => state.permission.allRoutes,
      adminUserId: (state) => state.user.adminUserId,
      origin: (state) => state.autoweb.origin,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1 || +this.currentLoginId === 25) {
        return true
      }
      return false
    }
  },
  methods: {
    numFormat,
    scrollInit () {
      const wrapper = document.getElementsByClassName('ant-table-scroll')[0]
      if (!wrapper) {
        return
      }
      // 获取要绑定事件的元素
      const table = wrapper.getElementsByClassName('ant-table-body')[0]
      if (!table) {
        return
      }
      var flag // 鼠标按下
      var downX // 鼠标点击的x下标
      var scrollLeft // 当前元素滚动条的偏移量
      table.addEventListener('mousedown', function (event) {
        flag = true
        downX = event.clientX // 获取到点击的x下标
        scrollLeft = this.scrollLeft // 获取当前元素滚动条的偏移量
      })
      table.addEventListener('mousemove', function (event) {
        if (flag) {
          // 判断是否是鼠标按下滚动元素区域
          var moveX = event.clientX // 获取移动的x轴
          var scrollX = moveX - downX // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
          this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
          table.scrollTo(this.scrollLeft, 0)
        }
      })
      // 鼠标抬起停止拖动
      table.addEventListener('mouseup', function () {
        flag = false
      })
      // 鼠标离开元素停止拖动
      table.addEventListener('mouseleave', function (event) {
        flag = false
      })
    },
    async changeSdkSts (sts, record) {
      this.isLoading = true
      record.sts = sts ? 'A' : 'S'
      record.appId = this.query.appId
      record.groupId = this.defaultQuery.groupId
      record.testId = this.defaultQuery.testId
      const resp = await updateSource(record)
      if (resp.code === 200) {
        this.$message.success('修改成功！')
        this.filterData[0].name = this.openNum + (sts ? 1 : 0) + '个广告源开启'
        this.dataList[0].name = this.openNum + (sts ? 1 : 0) + '个广告源开启'
      }
      this.isLoading = false
    },
    changePlatIds (ids) {
      this.query.platIds = ids
      this.platIds = ids
      this.getTableData()
      this.$emit('changePlatId', this.platIds)
    },
    // 获取全部广告平台列表
    async getplatArr () {
      const resp = await platformLinkList()
      if (resp.code === 200) {
        this.platArr = resp.data || []
      }
    },
    // 导出报表
    async downloadReport () {
      this.downloading = true
      const codeList = []
      const nameList = ['优先级', '广告平台', '广告源', '状态', '价格']
      this.sureArr.forEach((item) => {
        codeList.push(item.prop)
        nameList.push(item.label)
      })
      const query = { ...this.query, ...this.defaultQuery }
      query.codeList = codeList
      query.nameList = nameList
      try {
        const res = await this.exportToExcel(query)
        await excel(res, `aggregate.xlsx`)
      } catch (e) {
        console.log(e, '导出excel错误信息')
      }
      this.downloading = false
    },
    // 导出表格接口函数
    exportToExcel (query) {
      return request.post('/aggregate/downloadReport', query, {
        responseType: 'blob',
        onUploadProgress: (progress) => {}
      })
    },
    changeCustTags (targetArr, sureArr) {
      this.targetArr = targetArr
      this.sureArr = sureArr
      this.getColumns()
    },
    // 自定义指标
    handleClickCustTagsBtn () {
      this.custTagsVisible = true
    },
    // 获取指标列表数组
    initTargetArr () {
      const tagInfo = localStorage.getItem(this.storageName)
      const { sureArr, targetArr } = custTagObj
      this.defaultCheckedList = sureArr.map((item) => ({ ...item }))
      if (this.role !== 'SUPER_ADMIN' && this.currentLoginId !== 25) {
        targetArr.forEach((item) => {
          item.list = item.list.filter((child) => !child.superAdminShow)
        })
      }
      this.targetArr = JSON.parse(JSON.stringify(targetArr))
      if (!tagInfo) {
        this.sureArr = JSON.parse(JSON.stringify(sureArr))
      } else {
        this.sureArr = JSON.parse(tagInfo).sureArr
      }
      this.getColumns()
    },
    // 获取columns
    getColumns () {
      // this.sureLength = 0
      const num = this.sureArr.length
      this.sureColumns = this.sureArr.map((item) => {
        // this.sureLength += item.width
        return {
          title: (
            <div>
              <m-tips showIcon={false} content={item.desc} title={item.label} width={150}></m-tips>
            </div>
          ),
          dataIndex: item.prop,
          width: num <= 12 ? null : item.width,
          align: 'center',
          scopedSlots: { customRender: item.prop }
        }
      })
      this.columns = [...this.baseColumns, ...this.sureColumns, ...this.actionColumn]
    },

    rowClassName (record, index) {
      if (index === 0) {
        return 'all_col'
      } else if (index === 1 && this.hasSdk !== -1) {
        return 'sdk_col'
      } else if (index === this.openNum + 2) {
        return 'expend_col'
      }
      // 正常广告源起始位置（开启）
      // 汇总数 + 竞价开启数 + sdk数
      const sdkNum = this.hasSdk !== -1 ? 1 : 0
      const startIndex = this.biddingOpenNum + 1 + sdkNum
      // 正常广告源结束位置（开启）
      // 开启数（竞价开启 和 固价开启） + sdk数 + 汇总数 - 1
      const endIndex = this.openNum + sdkNum + this.publicNum
      if (index >= startIndex && index <= endIndex) {
        // 瀑布流优先级
        const i = index - startIndex + 1
        if (Math.ceil(i / this.concurrentNum) % 2 === 0) {
          // group1
          return 'group1-row'
        } else if (Math.ceil(i / this.concurrentNum) % 2 === 1) {
          // group2
          return 'group2-row'
        }
      }
    },
    handleBatchAddSource () {
      this.$router.push({
        name: 'batchAddSource',
        query: {
          appId: this.query.appId,
          placeId: this.query.placeId,
          groupId: this.query.groupId,
          fromPage: 'config',
          testId: this.query.testId,
          positionId: this.positionId
        }
      })
    },
    handleSelectRecommendType () {
      this.$router.push({
        name: 'recommendTemplate',
        query: {
          appId: this.query.appId,
          placeId: this.query.placeId,
          groupId: this.query.groupId,
          testId: this.query.testId,
          positionId: this.positionId,
          type: this.recommendType
        }
      })
    },
    addRecommendTemplate () {
      this.showSelectRecommendType = true
    },
    handleBatchCopy () {
      this.batchCopyVisible = true
    },
    handleBatchExport () {
      this.batchExportVisible = true
    },
    batchCopySource () {
      this.batchCopySourceVisible = true
    },
    async batchOperation (status) {
      const _this = this
      this.$confirm({
        title: '提示',
        content: (h) => (
          <div>
            确定{status === 'A' ? '开启' : status === 'S' ? '关闭' : status === 'D' ? '删除' : ''}所选中的广告源嘛？
          </div>
        ),
        async onOk () {
          const batchOperationQuery = {
            sts: status,
            groupId: _this.query.groupId,
            placeId: _this.query.placeId,
            testId: _this.query.testId,
            ids: _this.selectTableItems
          }
          const resp = await batchUpdateSourceSts(batchOperationQuery)
          if (resp.code === 200) {
            _this.$message.success(`操作成功！`)
            _this.getTableData()
          }
        },
        onCancel () {}
      })
    },
    handleAddSource () {
      this.isAdd = true
      this.addSourceVisible = true
    },
    handleRowChange (selectedRowKeys, selectedRows) {
      this.selectTableItems = selectedRowKeys
    },
    handleRowSelect (record, selected, selectedRows) {},
    handleRowSelectAll (selected, selectedRows, changeRows) {},
    handleBtnEditBiddfloor (e, row, index) {
      row.groupId = this.query.groupId
      row.testId = this.query.testId
      row.appId = this.query.appId
      row.bidfloor = row.oldBidfloor
      updateSource(row).then((res) => {
        if (res.code === 200) {
          this.$message.success('价格修改成功！')
          this.$refs['bidfloor_popover_' + row.id].$children[0].sVisible = false
          this.getTableData()
        }
      })
    },
    // 折叠按钮
    handleExpend () {
      this.isExpend = !this.isExpend
    },
    getPlatName (id) {
      return this.platList.filter((item) => item.id === id)[0].name
    },
    // 获取table数据
    async getPlatList () {
      const resp = await getPlatList()
      this.platList = resp.data || []
    },
    async getTableData () {
      this.selectTableItems = []
      this.isLoading = true
      const xquery = { ...this.query, ...this.defaultQuery }
      const resp = await getAggregateTableData(xquery)
      this.publicAdPool = resp.data?.publicAdPool || 0
      this.maxAdvResponseTime = resp.data && resp.data.maxAdvResponseTime
      // this.isExpend = true
      this.dataList = []
      // 汇总数据
      const sum = resp.data.reportData || []
      sum.advResponse = sum.response
      // 正常广告源
      let sources = resp.data.sources || []
      sources = sources.map((item) => ({ ...item.reportData, ...item }))
      // 保存原价  type sources 正常
      sources.forEach((item) => {
        this.$set(item, 'type', 'sources')
        item.oldBidfloor = item.bidfloor
      })
      // 预算
      this.hasSdk = sources.findIndex((item) => +item.platId === 0)
      let sdkArr = []
      if (this.hasSdk !== -1) {
        sdkArr = sources.splice(0, 1)
        this.$set(sdkArr[0], 'type', 'sdk')
        this.sdkNum = 1
      } else {
        this.sdkNum = 0
      }
      // 公共广告源
      this.hasPublic = sources.findIndex((item) => +item.platId === 99999)
      let publicArr = []
      if (this.hasPublic !== -1) {
        const i = sources.findIndex((item) => item.platId === 99999)
        publicArr = sources.splice(i, 1)
        this.$set(publicArr[0], 'type', 'public')
        this.publicNum = 1
      } else {
        this.publicNum = 0
      }
      // 所有的开启正常广告源
      const allSourcesOpenDatas = sources.filter((item) => item.sts === 'A')
      // 开启的 竞价 正常广告源
      const biddingSourcesOpenDatas = allSourcesOpenDatas.filter((item) => item.settleType) // settleType 1,2,3
      // 开启的 固价 正常广告源
      const fixedSourcesOpenDatas = allSourcesOpenDatas.filter((item) => item.settleType === 0)
      // 固价优先级
      fixedSourcesOpenDatas.forEach((item, i) => {
        item.priority = i + 1
      })
      this.biddingOpenNum = biddingSourcesOpenDatas.length

      // 所有的关闭的正常广告源
      const allSourcesCloseDatas = sources.filter((item) => item.sts === 'S')
      // 关闭的 竞价 正常广告源
      const biddingSourcesCloseDatas = allSourcesCloseDatas.filter((item) => item.settleType) // settleType 1,2,3
      // 关固价（关闭）闭的 固价 正常广告源
      const fixedSourcesCloseDatas = allSourcesCloseDatas.filter((item) => item.settleType === 0)
      //

      // 兜底广告源
      let bottoms = resp.data.bottomSourceList || []
      bottoms = bottoms.map((item) => ({ ...item.reportData, ...item }))
      // 保存原价  type bottoms 兜底
      bottoms.forEach((item) => {
        this.$set(item, 'type', 'bottoms')
        item.oldBidfloor = item.bidfloor
      })
      // 所有的开启的兜底广告源
      const allBottomsOpenDatas = bottoms.filter((item) => item.sts === 'A')
      // 所有的关闭的兜底广告源
      const allBottomsCloseDatas = bottoms.filter((item) => item.sts === 'S')

      // 开启的 广告源数量（不包括预算广告源）
      this.openNum = biddingSourcesOpenDatas.length + fixedSourcesOpenDatas.length + allBottomsOpenDatas.length
      // 关闭的 广告源数量（不包括预算广告源）固价（关闭）
      this.closeNum = biddingSourcesCloseDatas.length + fixedSourcesCloseDatas.length + allBottomsCloseDatas.length
      //

      // 数据push
      sum.type = 'all'
      sum.id = 'all'
      sum.name =
        this.openNum + ([...sdkArr, ...publicArr].filter((item) => item.sts === 'A').length) + '个广告源开启'
      // 流量填充率
      sum.advRequestFilledRate = sum.requestFilledRate
      // 流量请求
      sum.advRequest = sum.request
      // push 汇总
      this.dataList = [sum, ...sdkArr, ...publicArr, ...biddingSourcesOpenDatas, ...fixedSourcesOpenDatas, ...allBottomsOpenDatas]
      // 有未开启的广告源
      const expandRow = {
        text: '未启用（' + this.closeNum + '个广告源)',
        type: 'expend',
        id: 'expend'
      }
      if (this.closeNum > 0) {
        this.dataList = [
          ...this.dataList,
          expandRow,
          // 竞价（关闭）
          ...biddingSourcesCloseDatas,
          // 固价（关闭）
          ...fixedSourcesCloseDatas,
          // 兜底（关闭）
          ...allBottomsCloseDatas
        ]
      } else {
        // 有未开启的广告源
      }
      // 开启的+关闭的广告源数量 （不包括预算广告源）
      if (this.closeNum === 0 && this.openNum === 0) {
        // 预算广告源
        this.dataList = [sum, ...sdkArr, ...publicArr]
      }
      // 展示总数据
      this.filterData = this.dataList.slice(0, this.openNum + 2 + sdkArr.length + publicArr.length)
      // this.filterData.map((item) => {
      //   item.request = numFormat(item.request, 3, '')
      //   item.impress = numFormat(item.impress, 3, '')
      //   item.income = numFormat(item.income, 3, '')
      //   item.ecpm = numFormat(item.ecpm, 3, '')
      //   item.advRequest = numFormat(item.advRequest, 3, '')
      //   item.advResponse = numFormat(item.advResponse, 3, '')
      //   item.click = numFormat(item.click, 3, '')
      //   item.askPriceRequest = numFormat(item.askPriceRequest, 3, '')
      //   item.askPriceResponse = numFormat(item.askPriceResponse, 3, '')
      //   item.response = numFormat(item.response, 3, '')
      //   item.askPriceResponse = numFormat(item.askPriceResponse, 3, '')
      //   item.estimatedRevenueEcpm = numFormat(item.estimatedRevenueEcpm, 3, '')
      //   item.unitRequest = numFormat(item.unitRequest, 3, '')
      //   item.unitImpression = numFormat(item.unitImpression, 3, '')
      //   item.unitClick = numFormat(item.unitClick, 3, '')
      // })
      this.filterDataTemp = JSON.parse(JSON.stringify(this.filterData))
      this.isLoading = false
      // 获取并行请求数
      this.groupList = resp.data?.groups || []
      if (this.groupList.length > 0) {
        this.abTests = this.groupList.find((item) => item.id === this.defaultQuery.groupId)?.abTests
        this.concurrentNum = this.abTests.find((item) => item.groupId === this.defaultQuery.groupId)?.concurrentNum
      }
    }
  }
}
</script>
<style lang="less">
.bidfloor_popover {
  .ant-popover-content {
    .ant-popover-inner-content {
      padding: 5px 10px;
    }
  }
}

.recommend-type-box {
  position: relative;

  .recommend-type-header {
    padding: 10px 20px;
    font-size: 14px;
  }

  .recommend-type-content {
    display: flex;

    .recommend-type-item {
      margin: 20px;
      flex-grow: 1;
      border: 1px solid #ccc;
      height: 300px;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      display: flex;
      padding: 20px;
      flex-direction: column;
      transition: all 0.3s;

      .recommend-type-item-header {
        color: @primary-color;

        .title {
          font-size: 16px;
          font-weight: 600;
        }

        .desc {
          font-size: 14px;
        }
      }

      .recommend-type-item-img {
        flex-grow: 1;
        padding: 30px 20px 20px 20px;
        position: relative;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
        }
      }

      &.active {
        border-color: @primary-color;
        background: #f3f6ff;
      }
    }
  }
}
</style>

<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';

.aggregate-table-container {
  margin: 10px 0 0 0;
  width: 100%;
  background: #fff;
  .aggregate_table_middle {
    display: flex;
    margin-bottom: 20px;
    padding: 15px;
    justify-content: flex-end;
  }

  .table_wrapper {
    width: 100%;
    padding: 0 20px 0 20px;
  }
}

::v-deep.aggregate-table-container {
  .ant-btn.ant-btn-primary {
    border-radius: 5px 0 0 5px;
    background: @primary-color;
    height: 36px;
  }

  // .ant-table-fixed-left {
  //   box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
  // }

  .ant-btn.ant-btn-primary.ant-dropdown-trigger {
    border-radius: 0 5px 5px 0;
    height: 36px;
  }

  .ant-table-wrapper table {
    font-size: 12px;
    // 处理fixed后的错位问题
    .ant-table-tbody > tr > td {
      white-space: nowrap;
      height: 56px !important;
    }
    // 设置表头样式
    thead.ant-table-thead {
      tr th {
        // background: #f1f1f1;
        font-weight: 600;
        font-size: 13px;
      }
      // 防止吸顶后非固定列覆盖固定列
      .ant-table-align-center.ant-table-row-cell-break-word {
        z-index: -1;
      }
    }
  }
  .ant-table.ant-table-layout-fixed {
    table {
      table-layout: none;
    }
  }
}
::v-deep.aggregate-table-container .more_setting_popover_auto {
  border: 1px solid;
}
::v-deep {
  .ant-table-placeholder {
    border-bottom: none;
  }
}
.more_setting {
  height: 24px;
  line-height: 24px;
  cursor: pointer;
  padding: 0 5px;

  &:hover {
    background: fade(@primary-color, 5%);
  }

  .icon {
    margin-right: 14px;

    &.setting_icon {
      color: @primary-color;
    }

    &.delete_icon {
      color: red;
    }

    &.waring_icon {
      color: orange;
    }
  }
}

.bidfloor {
  cursor: pointer;

  .auto_price {
    display: inline-block;
    margin: 3px 3px 0 0;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 3px solid #e7e8ec;

    &.isopen {
      background-color: @primary-color;
    }

    &.isclose {
      background-color: #bbbdc3;
    }
  }

  .edit_bidfloor_icon {
    display: none;
    color: @primary-color;
    font-size: 14px;
    margin-left: 3px;
  }

  &:hover .edit_bidfloor_icon {
    display: inline-block;
  }
}

::v-deep {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
}
</style>

<style lang="less">
// 瀑布流及选择按钮隐藏
.aggregate_table {
  overflow: hidden;
  position: relative;
  width: 100%;
  .ant-table-selection-column.ant-table-selection-column-custom .ant-checkbox.ant-checkbox-disabled {
    display: none;
  }

  .group1-row {
    background-color: rgb(255, 255, 255);
  }

  .group2-row {
    background-color: rgb(245, 252, 252);
  }

  .ant-table-scroll {
    user-select: none;
    cursor: move;
  }
}
.more_icon{
  padding: 0 5px;
}
.more_setting_popover_auto{
    .ant-popover-inner-content{
      padding: 6px 2px;
      .more_setting{
        display: flex;
        align-items: center;
        padding: 2px 6px;
        margin: 3px 0;
      }
    }
}
</style>
